/*
* Gallery and Gallery-boxes including grid layout, spacing, shadow, details on hover etc
*/

.gallery {
  padding-top: 8vh;
}

/* Formatting boxes*/
.galleryFigure {
  margin-bottom: 10vh;
  margin-inline: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 10vw;
  width: 80vw;
}

.galleryFigureImage {
  flex: 50%;
  max-height: 80vh;
  max-width: 50vw;
  object-fit: contain;
  object-position: right;
  margin-left: auto;
}

.galleryFigureCaption {
  flex: 50%;
  font-size: small;
  text-align: right;
  margin-top: auto;
  padding-right: 1.5em;
}

@media screen and (orientation: portrait) {
  .gallery {
      padding-top: 30vh;
  }
}

  .galleryFigureCaptionTitle {
  display: inline;
  font-size: medium;
  font-weight: lighter;
}
