body {
  font-family: var(--w4a-font-family);
  font-size: 1.5em;
  background-color: var(--w4a-background-primary);
  color: var(--w4a-text-primary);
}

/*
 * Position items on the screen
 */

/* Push footer to end of page if no content */
.website4art {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between
}

/* Centralize and size header title */
.headerTitle {
  background-color: var(--w4a-background-primary);
  padding-top: 5vh;
  font-size: xxx-large;
  font-weight: lighter;
  margin-left: 10vw;
}

/* Centralize footer copyright */
.footerCopyright {
  text-align: center;
  font-size: smaller;
  display: inherit;
}

/* Let header / footer links be in a line without bullets and spread over full width */
.footerList {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding-inline-start: 0em;
  width: 80vw;
  margin-left: 10vw;
  margin-right: 10vw;
}

.headerList {
  list-style-type: none;
  padding-inline-start: 0em;
  margin-left: 10vw;
  margin-right: 10vw;
}

.footerList {
  border-top: 1px solid var(--w4a-border);
}

.footerListItem {
  float: left;
}

/* Line above footer and below header to separate from content */
.footer {
  font-size: smaller;
  margin-top: 0.5em;
  padding-bottom: 1em;
}

.header {
  margin-bottom: 0.5em;
  position: fixed;
  top: 0;
}

/* create a law paragraph symbol as this is not available in font awesome */
.law-symbol::before {
  content: "§";
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Das Kleingedruckte */
.lawtext {
  padding-left: 30vw;
  padding-right: 10vw;
  padding-top: 8vh;
  font-size: smaller;
}
