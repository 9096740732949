/*
* Impressions and Impressions-boxes including grid layout, spacing, shadow, details on hover etc
*/

/* Formatting boxes*/
.impressionsFigure {
  margin-bottom: 10vh;
}

.impressionsFigure > .impressionsFigureImage {
  height: 80vh;
  width: 100%;
  min-height: 0;
  min-width: 0;
  object-fit: contain;
  display: block;
  margin: auto;
  /*
  box-shadow: 0.25vw 0.25vw 0.25vw var(--w4a-border);
  background-color: var(--w4a-background-secondary);
  */
}

.impressionsFigureCaption {
  font-size: small;
  text-align: center;
}

@media screen and (hover: hover) {
  .impressionsFigureCaption {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  .impressionsFigureCaption:hover {
    opacity: 1;
  }
}

.impressionsFigureCaptionTitle {
  font-size: medium;
  font-weight: lighter;
}

/* Sizing boxes */
/*
.impressionsFigure {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.impressionsFigureImage {
  flex-grow: 1;
  flex-shrink: 1;
}

.impressionsFigureCaption {
  flex-grow: 0;
  flex-shrink: 0;
}
*/

/* Scroll behavior */
/* More bugs then features...
html {
  scroll-snap-type: y mandatory;
}
body { 
  scroll-snap-type: y mandatory;
}
.impressionsFigure, .scrollTarget  {
  overflow: hidden;
  scroll-snap-align: center;
}
*/