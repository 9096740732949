/*
* Format Bio
*/

/* Indent Bio */
.bio {
  padding-left: 30vw;
  padding-right: 10vw;
  padding-top: 8vh;
}
  
  /* Make titles more visible */
  .bioQuotesTitle, .bioExhibitionsTitle, .bioCurrentsTitle, .bioUpcomingsTitle, .bioPositionsTitle {
    margin-top: 1em;
    margin-bottom: 0.25em;
    font-weight: bold;
  }
  
  /* Make quotes italic */
  .bioQuotesQuote {
    margin: 0.25em;
    border-left: 0.5em solid var(--w4a-border);
    padding-left: 0.5em;
    font-style: italic;
    max-width: 50vw;
  }
  
  /* Make names more visible */
  .bioCurrentsName, .bioUpcomingsName, .bioPositionsDate, .bioExhibitionsDate, .bioExhibitionsFirstDate, .bioUpcomingsDate {
    font-weight: bold;
  }
  
  /* Make names more visible */
  .bioExhibitionsDate, .bioUpcomingsDate {
    padding-top: 1em;
  }

  /* Reduce space between paragraphs */
  .bioQuotesText, .bioExhibitionsText, .bioCurrentsText, .bioUpcomingsText, .bioPositionsText {
    margin-block-end: 0.25em;
    margin-block-start: 0;
  }

  /* Source under quote is right aligned */
  .bioQuotesSource {
    text-align: right;
  } 
